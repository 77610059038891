import { ContactPreferenceType, DocumentKey, PopupPreference, Preference } from '~/types'

export const useContactPreferencePopup = () => {
  const config: Record<Preference, PopupPreference> = {
    [Preference.INVOICES]: {
      contactPreferenceType: ContactPreferenceType.LOCATION,
      documentName: 'Facturen',
      minEmail: 1,
      maxEmail: 2,
      documentSections: [
        {
          documentKey: DocumentKey.FACTUUR,
          translationKey: 'invoice'
        }
      ]
    },
    [Preference.TRANSPORT_DOCUMENTS]: {
      contactPreferenceType: ContactPreferenceType.LOCATION,
      documentName: 'Transportdocumenten',
      minEmail: 0,
      maxEmail: 2,
      documentSections: [
        {
          documentKey: DocumentKey.PAKBON,
          translationKey: 'packingSlipTransportDocument'
        },
        {
          documentKey: DocumentKey.VRACHTBRIEF,
          translationKey: 'waybillTransportDocument'
        },
        {
          documentKey: DocumentKey.RETOURBEVESTIGING,
          translationKey: 'returnSlipTransportDocument'
        }
      ]
    },
    [Preference.PICK_CORRECTIONS]: {
      contactPreferenceType: ContactPreferenceType.LOCATION,
      documentName: 'Pick-correcties',
      minEmail: 0,
      maxEmail: 2,
      documentSections: [
        {
          documentKey: DocumentKey.PICKCORRECTIE,
          translationKey: 'pickingCorrection'
        }
      ]
    },
    [Preference.ARTICLES_OVERVIEW]: {
      contactPreferenceType: ContactPreferenceType.LOCATION,
      documentName: 'Artikeloverzichten',
      minEmail: 1,
      maxEmail: 2,
      documentSections: [
        {
          documentKey: DocumentKey.ARTIKELOVERZICHT,
          translationKey: 'articlesOverview'
        }
      ]
    },
    [Preference.ORDER_CONFIRMATIONS]: {
      contactPreferenceType: ContactPreferenceType.PERSONAL,
      documentName: 'Orderbevestigingen'
    }
  }

  return { config }
}
